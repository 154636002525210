import React from "react"
import SEO from "../components/seo"
import Main from "./main"
const IndexPage = () => (
  <>
    <SEO title="Aadil Hasan Portfolio" />
    <Main />
  </>
)

export default IndexPage
